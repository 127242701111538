import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/ProductListing';
import About from '../pages/About/About';
import Contact from '../pages/Contact/Contact';
import Product from '../pages/Product/Product';
import Cart from '../pages/Cart/Cart';
import Checkout from '../pages/Checkout/Checkout';
import Success from '../pages/Success/Success';
import OrderTracking from '../pages/OrderTracking/OrderTracking';

function UserRoutes() {
  return (
    <Routes>
        <Route path="/" element={<Home />} key="home" />,
        <Route path="/about" element={<About />} key="about" />,
        <Route path="/contact" element={<Contact />} key="contact" />,
        <Route path="/product/:id" element={<Product />} key="product" />,
        <Route path="/cart" element={<Cart />} key="cart" />,
        <Route path="/checkout" element={<Checkout />} key="checkout" />,
        <Route path="/success" element={<Success />} key="success" />,
        <Route path="/orderTracking" element={<OrderTracking />} key="orderTracking" />,
    </Routes>
  )
}

export default UserRoutes;
