export const printOrderDetails = (order, orderId, userDetails) => {
  try {
    const printWindow = window.open('', '_blank');
    
    printWindow.document.write(`
      <html>
        <head>
          <title>Order Details - ${orderId}</title>
          <style>
            body { 
              font-family: Arial, sans-serif; 
              padding: 20px;
              max-width: 800px;
              margin: 0 auto;
            }
            .header { 
              text-align: center;
              margin-bottom: 30px;
              padding-bottom: 10px;
              border-bottom: 2px solid #333;
            }
            .header h1 { 
              font-size: 24px; 
              margin-bottom: 10px;
            }
            .section {
              margin-bottom: 20px;
            }
            .section h2 {
              font-size: 18px;
              margin-bottom: 10px;
              padding-bottom: 5px;
              border-bottom: 1px solid #ddd;
            }
            table { 
              width: 100%; 
              border-collapse: collapse; 
              margin-top: 10px;
            }
            th, td { 
              border: 1px solid #ddd; 
              padding: 8px; 
              text-align: left; 
            }
            th { 
              background-color: #f8f9fa; 
            }
            .info-grid {
              display: grid;
              grid-template-columns: auto 1fr;
              gap: 8px;
              margin-bottom: 15px;
            }
            .label {
              font-weight: bold;
              min-width: 150px;
            }
            @media print {
              body { padding: 0; }
              button { display: none; }
              @page { margin: 2cm; }
            }
          </style>
        </head>
        <body>
          <div class="header">
            <h1>Order Details</h1>
            <div>Date: ${new Date().toLocaleDateString()}</div>
          </div>

          <div class="section">
            <h2>Order Information</h2>
            <div class="info-grid">
              <span class="label">Order ID:</span>
              <span>${orderId}</span>
              <span class="label">Order Date:</span>
              <span>${order.orderDate.toDate().toLocaleString()}</span>
              <span class="label">Total Amount:</span>
              <span>₹${order.totalAmount}</span>
              <span class="label">Payment ID:</span>
              <span>${order.razorpayOrderId || 'Pending'}</span>
              <span class="label">Status:</span>
              <span>${order.status}</span>
              <span class="label">Assigned To:</span>
              <span>${order.assignedTo || 'Not assigned'}</span>
            </div>
          </div>

          <div class="section">
            <h2>Customer Information</h2>
            <div class="info-grid">
              <span class="label">Name:</span>
              <span>${userDetails?.billingName || 'NA'}</span>
              <span class="label">Email:</span>
              <span>${userDetails?.email || 'NA'}</span>
              <span class="label">Mobile:</span>
              <span>${userDetails?.mobileNumber || 'NA'}</span>
              <span class="label">Shipping Address:</span>
              <span>${order.shippingAddress || 'NA'}</span>
            </div>
          </div>

          <div class="section">
            <h2>Ordered Items</h2>
            <table>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Color</th>
                  <th>Size</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                ${order.items.flatMap((item, index) =>
                  item.selections.map((selection, selIndex) => `
                    <tr>
                      <td>${item.name}</td>
                      <td>${selection.color}</td>
                      <td>${selection.size}</td>
                      <td>${selection.quantity}</td>
                    </tr>
                  `).join('')
                )}
              </tbody>
            </table>
          </div>

          <button onclick="window.print()" style="margin-top: 20px; padding: 10px;">Print Document</button>
        </body>
      </html>
    `);
    
    printWindow.document.close();
  } catch (error) {
    console.error('Error preparing order details for print:', error);
    alert('Failed to prepare order details for printing.');
  }
};

export const printManifest = (order, orderId, userDetails) => {
  try {
    const printWindow = window.open('', '_blank');
    
    printWindow.document.write(`
      <html>
        <head>
          <title>Order Manifest - ${orderId}</title>
          <style>
            body { 
              font-family: Arial, sans-serif; 
              padding: 20px;
              max-width: 800px;
              margin: 0 auto;
            }
            .header { 
              text-align: center;
              margin-bottom: 30px;
              padding-bottom: 10px;
              border-bottom: 2px solid #333;
            }
            .header h1 { 
              font-size: 24px; 
              margin-bottom: 10px;
            }
            .manifest-info {
              display: grid;
              grid-template-columns: auto 1fr;
              gap: 10px;
              margin-bottom: 30px;
            }
            .label {
              font-weight: bold;
              min-width: 150px;
            }
            .value {
              margin-left: 10px;
            }
            .address-section {
              margin-top: 20px;
              padding: 15px;
              border: 1px solid #ddd;
              border-radius: 4px;
            }
            @media print {
              body { padding: 0; }
              button { display: none; }
              @page { margin: 2cm; }
            }
          </style>
        </head>
        <body>
          <div class="header">
            <h1>Order Manifest</h1>
            <div>Generated on: ${new Date().toLocaleString()}</div>
          </div>

          <div class="manifest-info">
            <span class="label">Customer ID:</span>
            <span class="value">${order.userId || 'NA'}</span>
            
            <span class="label">Order ID:</span>
            <span class="value">${orderId}</span>
            
            <span class="label">Customer Name:</span>
            <span class="value">${userDetails?.billingName || 'NA'}</span>
            
            <span class="label">Mobile Number:</span>
            <span class="value">${userDetails?.mobileNumber || 'NA'}</span>
            
            <span class="label">Payment Status:</span>
            <span class="value">${order.paymentStatus || 'NA'}</span>
            
            <span class="label">Order Date:</span>
            <span class="value">${order.orderDate.toDate().toLocaleString()}</span>
          </div>

          <div class="address-section">
            <div class="label">Shipping Address:</div>
            <div style="white-space: pre-line; margin-top: 10px;">
              ${order.shippingAddress || 'NA'}
            </div>
          </div>

          <button onclick="window.print()" style="margin-top: 20px; padding: 10px;">Print Document</button>
        </body>
      </html>
    `);
    
    printWindow.document.close();
  } catch (error) {
    console.error('Error preparing manifest for print:', error);
    alert('Failed to prepare manifest for printing.');
  }
}; 