import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import './ManageOrders.css';

const ManageOrders = () => {
  const [orders, setOrders] = useState([]);
  const [groupedOrders, setGroupedOrders] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState("Pending");
  const navigate = useNavigate();

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const ordersCollection = collection(db, 'orders');
      const ordersSnapshot = await getDocs(ordersCollection);
      const orderList = ordersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort by order date in descending order (latest first)
      orderList.sort((a, b) => b.orderDate.seconds - a.orderDate.seconds);
      setOrders(orderList);
      
      // Group orders by date
      const grouped = groupOrdersByDate(orderList.filter(order => 
        filterStatus === "All" ? true : order.status === filterStatus
      ));
      setGroupedOrders(grouped);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const groupOrdersByDate = (orderList) => {
    return orderList.reduce((groups, order) => {
      const date = new Date(order.orderDate.seconds * 1000).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });
      
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(order);
      return groups;
    }, {});
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleStatusFilterChange = (e) => {
    const status = e.target.value;
    setFilterStatus(status);
    const filtered = orders.filter(order => 
      status === "All" ? true : order.status === status
    );
    setGroupedOrders(groupOrdersByDate(filtered));
  };

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const orderRef = doc(db, 'orders', orderId);
      await updateDoc(orderRef, { status: newStatus });
      
      // Update local state
      const updatedOrders = orders.map(order =>
        order.id === orderId ? { ...order, status: newStatus } : order
      );
      setOrders(updatedOrders);
      
      // Regroup filtered orders
      const filtered = updatedOrders.filter(order => 
        filterStatus === "All" ? true : order.status === filterStatus
      );
      setGroupedOrders(groupOrdersByDate(filtered));
      
    } catch (error) {
      console.error('Error updating order status:', error);
      alert('Failed to update order status.');
    }
  };

  const handleViewDetails = (orderId) => {
    navigate(`/admin/order-details/${orderId}`);
  };

  if (loading) return <div>Loading orders...</div>;

  return (
    <div className="manage-orders">
      <h2>Manage Orders</h2>
      <div className="filter-refresh-container">
        <div className="filter-container">
          <label htmlFor="statusFilter">Filter by Status: </label>
          <select
            id="statusFilter"
            value={filterStatus}
            onChange={handleStatusFilterChange}
            className="status-filter-dropdown"
          >
            <option value="Pending">Pending</option>
            <option value="Shipped">Shipped</option>
            <option value="Delivered">Delivered</option>
            <option value="Cancelled">Cancelled</option>
            <option value="All">All</option>
          </select>
        </div>
        <button onClick={fetchOrders} className="btn-refresh">Refresh Orders</button>
      </div>

      {Object.entries(groupedOrders).map(([date, dateOrders]) => (
        <div key={date} className="date-group">
          <h6 className="date-header">{date}</h6>
          <table className="orders-table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Assigned To</th>
                <th>Total Amount</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dateOrders.map(order => (
                <tr key={order.id} className={order.status === 'Pending' ? 'pending-order' : ''}>
                  <td data-label="Order ID">{order.id}</td>
                  <td data-label="Assigned To">{order.assignedTo || 'Not assigned'}</td>
                  <td data-label="Total Amount">{order.totalAmount}₹</td>
                  <td data-label="Status">
                    <select
                      value={order.status}
                      onChange={(e) => handleStatusChange(order.id, e.target.value)}
                      className="status-dropdown"
                    >
                      <option value="Pending">Pending</option>
                      <option value="Shipped">Shipped</option>
                      <option value="Delivered">Delivered</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </td>
                  <td data-label="Actions">
                    <button
                      onClick={() => handleViewDetails(order.id)}
                      className="btn-view-details"
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default ManageOrders;
