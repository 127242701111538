import { styled } from "@mui/material";
import { Typography } from "@mui/material";

export const primaryColor = '#33387C';
export const headerBgColor = '#FFFFFF';
export const textColor = '#000000'

export const TitleTypography = styled(Typography)(({ theme }) => ({
  marginRight: '16px',
  display: 'flex', // Default value
  fontFamily: 'monospace',
  fontWeight: 700,
  color: textColor,
  textDecoration: 'none',
  cursor: 'pointer',
  
  // Responsive display based on breakpoints
  [theme.breakpoints.down('md')]: {
    display: 'none', // Hide on small screens
  },
}));