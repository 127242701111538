import React, { useState } from 'react';
import { db } from '../../firebase-config';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './OrderTracking.css';

const OrderTracking = () => {
  const [orderId, setOrderId] = useState('');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setOrderId(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setOrders([]);

    try {
      const orderRef = doc(db, 'orders', orderId);
      const orderDoc = await getDoc(orderRef);

      if (orderDoc.exists()) {
        const fetchedOrder = {
          id: orderDoc.id,
          ...orderDoc.data(),
        };
        setOrders([fetchedOrder]);
      } else {
        alert('No order found with the provided ID.');
      }
    } catch (error) {
      console.error('Error fetching order:', error);
      alert('An error occurred while fetching the order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleViewInvoice = (invoiceId) => {
    navigate(`/invoice/${invoiceId}`);
  };

  return (
    <div className="order-tracking-container" style={{ marginTop: '10px'}}>
      <h2>Track Your Order</h2>
      <form onSubmit={handleSearch} className="order-tracking-form">
        <div className="form-group">
          <label>Enter Your Order ID:</label>
          <input
            type="text"
            value={orderId}
            onChange={handleInputChange}
            placeholder="Enter your order ID"
            required
          />
        </div>
        <button type="submit" className="btn-submit">
          Track Order
        </button>
      </form>

      {loading ? (
        <p>Loading orders...</p>
      ) : (
        <div className="orders-list">
          {orders.length > 0 ? (
            orders.map((order) => (
              <div key={order.id} className="order-card">
                <h3>Order ID: {order.id}</h3>
                <p>Total Amount: {order.totalAmount}₹</p>
                <p>Order Date: {new Date(order.orderDate.seconds * 1000).toLocaleString()}</p>
                <p>Shipping Method: {order.shippingMethod}</p>
                <p>Status: {order.status || 'Pending'}</p>
                {order.invoiceId && (
                  <button 
                    onClick={() => handleViewInvoice(order.invoiceId)}
                    className="btn-view-invoice"
                  >
                    View Invoice
                  </button>
                )}
              </div>
            ))
          ) : (
            <p>No orders found for the provided order ID.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderTracking;