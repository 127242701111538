import React, { useState, useEffect } from 'react';
import './Checkout.css';
import { MdOutlineLocalShipping, MdOutlineLabel } from 'react-icons/md';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../firebase-config';
import { useCart } from '../../context/CartContext';
import { useNavigate } from 'react-router-dom';


const functions = getFunctions(app, "asia-south1");
const checkoutFunction = httpsCallable(functions, 'checkout'); // Define once here
const updateOrderStatusFunction = httpsCallable(functions, 'updateOrderStatus');

const Checkout = () => {
  const [deliveryDetails, setDeliveryDetails] = useState({
    billingName: '',
    mobileNumber: '',
    email: '',
    address: '',
    gstNumber: '',
    courierAddress: '',
  });

  const { state: cartState, dispatch } = useCart();  // Access dispatch to clear cart
  const [shippingMethod, setShippingMethod] = useState('normal');
  const [dropshippingLabel, setDropshippingLabel] = useState(null);
  const [loading, setLoading] = useState(false);  // Loader state
  const navigate = useNavigate();  // Add navigation

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDeliveryDetails({
      ...deliveryDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const handleShippingMethodChange = (e) => {
    setShippingMethod(e.target.value);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 1048576 && /image|pdf/.test(file.type)) {
      setDropshippingLabel(file);
    } else {
      alert('Only .jpg, .png, .pdf files are allowed, and file size must be under 1MB.');
    }
  };

  const handlePayment = async (razorpayOrderId, totalAmount, orderId, mobileNumber, billingName) => {
    const options = {
      key: 'rzp_test_lLNcldjgv8qrZH', // Replace with your Razorpay Test Key ID
      amount: totalAmount * 100, // Razorpay accepts amount in paise
      currency: "INR",
      name: "Your Store Name",
      description: "Test Transaction",
      order_id: razorpayOrderId, // Pass the Razorpay order ID here
      handler: async function (response) {
        try {
          // Payment was successful, update the order status to "Payment_Completed"
          await updateOrderStatusFunction({razorpayOrderId : razorpayOrderId, orderId: orderId, status: 'Pending', mobileNumber: mobileNumber, billingName: billingName });
          alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
          dispatch({ type: 'CLEAR_CART' }); // Clear the cart
          navigate('/success');
        } catch (error) {
          console.error('Error updating order status:', error);
          alert('Payment was successful, but an error occurred while updating the order status.');
        }
      },
      modal: {
        ondismiss: async function () {
          try {
            // User dismissed the payment, update the order status to "Payment_Failed"
            await updateOrderStatusFunction({ razorpayOrderId : null, orderId: orderId, status: 'Payment_Failed', mobileNumber: mobileNumber, billingName: billingName });
            alert('Payment was cancelled. Rolling back changes.');
          } catch (error) {
            console.error('Error updating order status:', error);
            alert('Payment cancellation occurred, but an error occurred while updating the order status.');
          }
        },
      },
      prefill: {
        name: deliveryDetails.billingName,
        email: deliveryDetails.email,
        contact: deliveryDetails.mobileNumber,
      },
      theme: {
        color: "#3399cc",
      },
    };
  
    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Use the single defined instance of checkoutFunction
      const response = await checkoutFunction({
        billingName: deliveryDetails.billingName,
        mobileNumber: deliveryDetails.mobileNumber,
        email: deliveryDetails.email,
        address: deliveryDetails.address,
        gstNumber: deliveryDetails.gstNumber,
        courierAddress: shippingMethod === 'courier' ? deliveryDetails.courierAddress : null,
        items: cartState.cartItems,
        totalAmount: cartState.totalPrice,
        shippingMethod,
      });
  
      // Extract Razorpay order ID from response
      const { razorpayOrderId, totalAmount, orderId, mobileNumber, billingName } = response.data;
      await handlePayment(razorpayOrderId, totalAmount, orderId, mobileNumber, billingName);
    } catch (error) {
      console.error('Checkout or Payment Error:', error);
      alert('An error occurred during checkout. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="checkout-container">
      <h2 className="page-title">Checkout Page - Delivery Details</h2>
      {loading && <div className="loader">Processing your order...</div>}  {/* Loader */}
      <form className="delivery-form" onSubmit={handleSubmit}>
        <div className="card-section">
          <h3>Personal Details</h3>
          <div className="form-group">
            <input
              type="text"
              name="billingName"
              value={deliveryDetails.billingName}
              onChange={handleInputChange}
              placeholder="Enter your full name"
              required
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              name="mobileNumber"
              value={deliveryDetails.mobileNumber}
              onChange={handleInputChange}
              placeholder="Enter your 10-digit mobile number"
              pattern="\d{10}" // Regular expression to enforce 10 digits
    maxLength="10" // Limit input length to 10 characters
    required
    title="Please enter a 10-digit mobile number" // Tooltip on validation error
  />
</div>


          <div className="form-group">
            
            <input
              type="email"
              name="email"
              value={deliveryDetails.email}
              onChange={handleInputChange}
              placeholder="Enter your email address"
              required
            />
          </div>
          <div className="form-group">
            
            <input
              type="string"
              name="gst"
              value={deliveryDetails.gstNumberemail}
              onChange={handleInputChange}
              placeholder="Enter your GST Number"
            />
          </div>

          <div className="form-group">
            <textarea
              name="address"
              value={deliveryDetails.address}
              onChange={handleInputChange}
              placeholder="Enter your full shipping address"
              required
            />
          </div>
        </div>

        <div className="card-section">
          <h3><MdOutlineLocalShipping /> Shipping Method</h3>
          <div className="shipping-options">
            <label>
              <input
                type="radio"
                value="normal"
                checked={shippingMethod === 'normal'}
                onChange={handleShippingMethodChange}
              />
              Normal Delivery - We handle delivery to your address
            </label>

            <label>
              <input
                type="radio"
                value="courier"
                checked={shippingMethod === 'courier'}
                onChange={handleShippingMethodChange}
              />
              Courier Address - Provide a courier address for us to ship to
            </label>

            <label>
              <input
                type="radio"
                value="delhiBike"
                checked={shippingMethod === 'delhiBike'}
                onChange={handleShippingMethodChange}
              />
              Delhi, within 2 hours delivery, by bike
            </label>

            <label>
              <input
                type="radio"
                value="dropshipping"
                checked={shippingMethod === 'dropshipping'}
                onChange={handleShippingMethodChange}
              />
              Dropshipping - Upload your shipping label
            </label>
          </div>
        </div>

        {shippingMethod === 'courier' && (
          <div className="card-section">
            <h3>Courier Address</h3>
            <div className="form-group">
              <label>Courier Address*</label>
              <textarea
                name="courierAddress"
                value={deliveryDetails.courierAddress}
                onChange={handleInputChange}
                placeholder="Enter the courier service address"
                required
              />
            </div>
          </div>
        )}

        {shippingMethod === 'dropshipping' && (
          <div className="card-section dropshipping-section">
            <h3><MdOutlineLabel /> Dropshipping Details</h3>
            <label>Upload Your Shipping Label (Only .jpg, .png, or .pdf, Max: 1MB):</label>
            <input type="file" accept=".jpg,.png,.pdf" onChange={handleFileUpload} />
            {dropshippingLabel && <p>Uploaded: {dropshippingLabel.name}</p>}
          </div>
        )}

        <button type="submit" className="btn-submit">Next</button>
      </form>
    </div>
  );
};

export default Checkout;
