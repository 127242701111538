import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase-config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useCart } from '../../context/CartContext';
import './Product.css';
import { useToast } from '../../context/ToastContext';

const Product = () => {
  const { id } = useParams();
  const [parentProduct, setParentProduct] = useState(null);
  const [skus, setSkus] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const { state, dispatch } = useCart();
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [groupedSkusByColor, setGroupedSkusByColor] = useState({});
  const toast = useToast();

  useEffect(() => {
    const fetchParentProduct = async () => {
      const parentProductQuery = query(
        collection(db, 'parent_products'),
        where('__name__', '==', id)
      );
      const parentProductSnapshot = await getDocs(parentProductQuery);

      if (!parentProductSnapshot.empty) {
        const productData = parentProductSnapshot.docs[0].data();
        productData.id = parentProductSnapshot.docs[0].id;
        setParentProduct(productData);
        setSelectedImage(productData.imageUrl); // Set initial image
      }
    };

    const fetchSkus = async () => {
      const skusQuery = query(collection(db, 'skus'), where('parentId', '==', id));
      const skusSnapshot = await getDocs(skusQuery);

      if (!skusSnapshot.empty) {
        const skusList = skusSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSkus(skusList);
        groupSkusByColor(skusList);
      }
    };

    const groupSkusByColor = (skus) => {
      const grouped = skus.reduce((acc, sku) => {
        if (!acc[sku.color]) {
          acc[sku.color] = {};  // Create a color key if it doesn't exist
        }
        acc[sku.color][sku.size] = sku;  // Assign size within that color
        return acc;
      }, {});
      setGroupedSkusByColor(grouped);
    };
    const restoreQuantitiesFromCart = () => {
      if (state.currentEditItem && state.currentEditItem.id === id) {
        // Restore quantities for the product being edited
        const initialQuantities = {};
        state.currentEditItem.selections.forEach((selection) => {
          initialQuantities[selection.id] = selection.quantity;
        });
        setSelectedQuantities(initialQuantities);
      }
    };

    fetchParentProduct();
    fetchSkus();
    restoreQuantitiesFromCart();
  }, [id, state.currentEditItem]);

  const handleThumbnailClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleQuantityChange = (skuId, quantity, maxStock) => {
    if (quantity > maxStock) {
      quantity = maxStock;
      alert(`Only ${maxStock} items are available for this SKU.`);
    }
    setSelectedQuantities((prevState) => ({
      ...prevState,
      [skuId]: quantity,
    }));
  };

  const handleAddToCart = () => {
    const selections = skus
      .filter((sku) => selectedQuantities[sku.id] > 0) // Only add SKUs with a quantity > 0
      .map((sku) => {
        const basePrice = calculatePrice(sku.pricing, sku.pricing[0].minQuantity);
        const lowerPrice = calculatePrice(sku.pricing, sku.pricing[1].minQuantity);
        return {
          id: sku.id,
          color: sku.color,
          size: sku.size,
          quantity: selectedQuantities[sku.id],
          gst: parentProduct.gst, 
          basePrice: basePrice || 0, // Price for smaller quantities
          lowerPrice: lowerPrice || 0, // Price for bulk quantities
        };
      });

    if (selections.length > 0) {
      dispatch({
        type: 'ADD_TO_CART',
        payload: {
          id: parentProduct.id,
          name: parentProduct.name,
          gst: parentProduct.gst, 
          selections,
        },
      });
      toast.success('Items added to the cart');
    } else {
      toast.error('Please select at least one SKU.');
    }
  };

  const calculatePrice = (pricing, quantity) => {
    const tier = pricing.find(
      (p) => quantity >= p.minQuantity && quantity <= p.maxQuantity
    );
    return tier ? tier.price : null;
  };

  if (!parentProduct) return <div>Loading...</div>;

  // Get distinct SKUs by color
  const uniqueColors = skus.reduce((colors, sku) => {
    if (!colors.includes(sku.color)) {
      colors.push(sku.color);
    }
    return colors;
  }, []);

  // Group SKUs by identical pricing (both min and max prices must match)
  const groupSkusByPricing = () => {
    const pricingGroups = {};

    skus.forEach(sku => {
      sku.pricing.forEach(priceTier => {
        const key = `${priceTier.minQuantity}-${priceTier.maxQuantity}:${priceTier.price}`;
        if (!pricingGroups[key]) {
          pricingGroups[key] = [];
        }
        pricingGroups[key].push(sku);
      });
    });

    return pricingGroups;
  };

  const groupedSkus = groupSkusByPricing();
  const availableSizes = [...new Set(Object.values(groupedSkusByColor).flatMap(skuGroup => Object.keys(skuGroup)))];


  return (
    <div className="product-page">
      <div className="product-content">
        <div className="product-image-section">
          <img
            src={selectedImage}
            alt={parentProduct.name}
            className="product-image-large"
          />

          <div className="product-thumbnails">
            {/* Main Product Image Thumbnail */}
            <img
              src={parentProduct.imageUrl}
              alt="Main Product"
              className={`thumbnail-image ${
                selectedImage === parentProduct.imageUrl ? 'active-thumbnail' : ''
              }`}
              onClick={() => handleThumbnailClick(parentProduct.imageUrl)}
            />

            {/* SKU Thumbnails (Only unique colors) */}
            {uniqueColors.map((color) => {
              const skuWithColor = skus.find((sku) => sku.color === color);
              return (
                <img
                  key={skuWithColor.id}
                  src={skuWithColor.imageUrl}
                  alt={`${skuWithColor.color} ${skuWithColor.size}`}
                  className={`thumbnail-image ${
                    selectedImage === skuWithColor.imageUrl ? 'active-thumbnail' : ''
                  }`}
                  onClick={() => handleThumbnailClick(skuWithColor.imageUrl)}
                />
              );
            })}
          </div>
        </div>

        <div className="product-details-section">
          <h1 className="product-title">{parentProduct.name}</h1>
          <p className="product-description">{parentProduct.description}</p>

          <div className="product-info-card">
            <ul className="product-info-list">
              <li>
                <span className="info-label">GST:</span>
                <span className="info-value">{parentProduct.gst}%</span>
              </li>
              <li>
                <span className="info-label">Weight:</span>
                <span className="info-value">
                  {parentProduct.weight ? `${parentProduct.weight} grams` : ' Not specified'}
                </span>
              </li>
            </ul>
          </div>

          <div className="selection-grid card">
            <h3>Select SKUs:</h3>
            <table className="selection-table">
              <thead>
                <tr>
                  <th>Color</th>
                  {availableSizes.map((size) => (
                    <th key={size}>{size}</th>  // Render size columns
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(groupedSkusByColor).map((color) => (
                  <tr key={color}>
                    <td>{color}</td>
                    {availableSizes.map((size) => (
                      <td key={size}>
                        {groupedSkusByColor[color][size] ? (
                          <input
                            type="number"
                            min="0"
                            max={groupedSkusByColor[color][size].stock}
                            value={selectedQuantities[groupedSkusByColor[color][size].id] || 0}
                            onChange={(e) =>
                              handleQuantityChange(
                                groupedSkusByColor[color][size].id,
                                parseInt(e.target.value),
                                groupedSkusByColor[color][size].stock
                              )
                            }
                            className="quantity-input"
                          />
                        ) : (
                          "-"
                        )}
                      </td>  // Render input box if SKU exists, else show "-"
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pricing Information Table */}
          <div className="pricing-info card">
            <h3>Pricing Information:</h3>
            <table className="pricing-table">
              <thead>
                <tr>
                <th>SKUs</th>
                  <th>Range (Quantity)</th>
                  <th>Price</th>
                
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupedSkus).map(([pricingKey, skuGroup], index) => {
                  const [range, price] = pricingKey.split(':');
                  return (
                    <tr key={index}>
                      <td>
                        {skuGroup.map(sku => (
                          <span key={sku.id}>{sku.color} {sku.size}, </span>
                        ))}
                      </td>
                      <td>{range.replace('-', ' to ')}</td>
                      <td>{price}₹</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Add to Cart Button */}
          <button onClick={handleAddToCart} className="add-to-cart-button">
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default Product;
