import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import './Invoice.css';

const Invoice = () => {
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const invoiceRef = doc(db, 'invoices', invoiceId);
        const invoiceDoc = await getDoc(invoiceRef);

        if (invoiceDoc.exists()) {
          setInvoice(invoiceDoc.data());
        } else {
          console.error('Invoice not found');
        }
      } catch (error) {
        console.error('Error fetching invoice:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [invoiceId]);

  const handlePrint = () => {
    window.print();
  };

  if (loading) return <div>Loading invoice...</div>;
  if (!invoice) return <div>Invoice not found</div>;

  return (
    <div className="invoice-container">
      <div className="invoice-actions no-print">
        <button onClick={handlePrint} className="btn-print">
          Print Invoice
        </button>
      </div>

      <div className="invoice-content">
        <div className="invoice-header">
          <h1>Tax Invoice</h1>
          <div className="invoice-details">
            <p><strong>Invoice No:</strong> {invoice.invoiceNo}</p>
            <p><strong>Date:</strong> {invoice.date.toDate().toLocaleDateString()}</p>
          </div>
        </div>

        <div className="company-details">
          <h2>Your Company Name</h2>
          <p>Company Address</p>
          <p>GST No: {invoice.gstNo}</p>
        </div>

        <div className="customer-details">
          <h3>Bill To:</h3>
          <p>User ID: {invoice.userId}</p>
          <p>Mobile: {invoice.mobileNumber}</p>
          <p>Address: {invoice.address}</p>
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th>Product Details</th>
              <th>HSN Code</th>
              <th>Qty</th>
              <th>Rate</th>
              <th>GST</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoice.products.map((item, index) => (
              item.selections.map((selection, selIndex) => (
                <tr key={`${index}-${selIndex}`}>
                  <td>{item.name} ({selection.color}, {selection.size})</td>
                  <td>{invoice.hsnCode}</td>
                  <td>{selection.quantity}</td>
                  <td>₹{item.price}</td>
                  <td>{invoice.gst}</td>
                  <td>₹{item.price * selection.quantity}</td>
                </tr>
              ))
            ))}
          </tbody>
        </table>

        <div className="invoice-summary">
          <p><strong>Payment Status:</strong> {invoice.paymentStatus}</p>
          <p><strong>Total Amount:</strong> ₹{invoice.total}</p>
        </div>
      </div>
    </div>
  );
};

export default Invoice; 