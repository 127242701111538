import React, { createContext, useReducer, useContext } from 'react';

// Initial cart state
const initialState = {
  cartItems: [],
  totalQuantity: 0,
  totalPrice: 0,
};

// Reducer to handle cart actions
const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART': {

      const { id, name, selections } = action.payload;
      const existingCartItemIndex = state.cartItems.findIndex(item => item.id === id);

      let updatedCartItems;

      if (existingCartItemIndex >= 0) {
        // If the item already exists in the cart, update it
        updatedCartItems = [...state.cartItems];
        updatedCartItems[existingCartItemIndex].selections = selections;
      } else {
        // Add new item to the cart
        updatedCartItems = [...state.cartItems, { id, name, selections }];
      }

      // Recalculate total price and quantity
      const totalQuantity = calculateTotalQuantity(updatedCartItems);
      const totalPrice = calculateTotalPrice(updatedCartItems);

      return {
        ...state,
        cartItems: updatedCartItems,
        totalQuantity,
        totalPrice,
      };
    }

    case 'REMOVE_FROM_CART': {
      const updatedCartItems = state.cartItems.filter(item => item.id !== action.payload.id);

      // Recalculate total price and quantity
      const totalQuantity = calculateTotalQuantity(updatedCartItems);
      const totalPrice = calculateTotalPrice(updatedCartItems);

      return {
        ...state,
        cartItems: updatedCartItems,
        totalQuantity,
        totalPrice,
      };
    }
    case 'SET_CURRENT_EDIT': {
      return {
        ...state,
        currentEditItem: action.payload, // Store the current product being edited
      };
    }
    default:
      return state;
  }
};

// Helper function to calculate total quantity
const calculateTotalQuantity = (cartItems) => {
  let totalQuantity = 0;
  cartItems.forEach(item => {
    item.selections.forEach(selection => {
      totalQuantity += selection.quantity;
    });
  });
  return totalQuantity;
};

// Helper function to calculate total price based on the quantity
const calculateTotalPrice = (cartItems) => {
  let totalQuantity = calculateTotalQuantity(cartItems);
  let totalPrice = 0;

  cartItems.forEach(item => {
     item.selections.forEach(selection => {
      const priceToUse = totalQuantity > 15 ? selection.lowerPrice : selection.basePrice;
      const gstAmount = (selection.gst / 100) * priceToUse; // Calculate GST based on price
      const priceWithGst = parseInt(priceToUse) + gstAmount; // Price including GST
    
      totalPrice += priceWithGst * selection.quantity;
    });
  });

  return totalPrice;
};

// Create CartContext
const CartContext = createContext();

// CartProvider component to wrap around components that need cart state
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use CartContext
export const useCart = () => useContext(CartContext);
