import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../admin/ProtectedRoute';
import AdminDashboard from '../admin/AdminDashboard';
import ManageUsers from '../admin/ManageUsers';
import ManageProducts from '../admin/ManageProducts';
import ManageOrders from '../admin/ManageOrders';
import ManageInventory from '../admin/ManageInventory';
import ParentProductForm from '../admin/ParentProductForm';
import OrderDetails from '../admin/OrderDetails';

function AdminRoutes() {
  return (
    <Routes>
        <Route
      path="/"
      element={
        <ProtectedRoute requiredRole="admin">
          <AdminDashboard />
        </ProtectedRoute>
      }
      key="admin-dashboard"
    />,
    <Route
      path="/manage-users"
      element={
        <ProtectedRoute requiredPermissions={["manage_users"]}>
          <ManageUsers />
        </ProtectedRoute>
      }
      key="manage-users"
    />,
    <Route
      path="/manage-products"
      element={
        <ProtectedRoute requiredPermissions={["manage_products"]}>
          <ManageProducts />
        </ProtectedRoute>
      }
      key="manage-products"
    />,
    <Route
      path="/manage-orders"
      element={
        <ProtectedRoute requiredPermissions={["manage_orders"]}>
          <ManageOrders />
        </ProtectedRoute>
      }
      key="manage-orders"
    />,
    <Route
      path="/manage-inventory"
      element={
        <ProtectedRoute requiredPermissions={["manage_inventory"]}>
          <ManageInventory />
        </ProtectedRoute>
      }
      key="manage-inventory"
    />,
    <Route
      path="/add-parent-product"
      element={
        <ProtectedRoute requiredPermissions={["manage_products"]}>
          <ParentProductForm />
        </ProtectedRoute>
      }
      key="add-parent-product"
    />,
    <Route
      path="/edit-parent-product/:productId"
      element={
        <ProtectedRoute requiredPermissions={["manage_products"]}>
          <ParentProductForm />
        </ProtectedRoute>
      }
      key="edit-parent-product"
    />,
    <Route
      path="/order-details/:orderId"
      element={
        <ProtectedRoute requiredPermissions={["manage_inventory"]}>
          <OrderDetails />
        </ProtectedRoute>
      }
      key="order-details"
    />,
    </Routes> 
  );
}

export default AdminRoutes;
