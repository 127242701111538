import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Success.css';

const Success = () => {
  const navigate = useNavigate();
  return (
    <div className="success-container">
      <h2>Order Placed Successfully!</h2>
      <p>Thank you for your purchase. Your order has been placed successfully.</p>
      <button className="back-home-button" onClick={() => navigate('/')}>
        Back to Home
      </button>
    </div>
  );
};

export default Success;
