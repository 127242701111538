import React, { useState, useEffect } from "react";
import { db, storage } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import "./ManageInventory.css";
import useAdminRole from '../hooks/useAdminRole';
import { useToast } from '../context/ToastContext'; // Import useToast

// Initial form state with pricing details
const initialFormState = () => ({
  parentId: "",
  color: "",
  size: "",
  sku: "",
  stock: 0,
  pricing: [
    { minQuantity: 1, maxQuantity: 15, price: "" },
    { minQuantity: 16, maxQuantity: 50, price: "" },
  ],
  imageUrl: "",
});

const ManageInventory = () => {
  const [skus, setSkus] = useState([]);
  const [parentProducts, setParentProducts] = useState([]);
  const [form, setForm] = useState(initialFormState());
  const [editingDocId, setEditingDocId] = useState(null); // Firestore document ID for editing
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast(); // Initialize toast

  const { role } = useAdminRole(); // Check user's role

  useEffect(() => {
    fetchSkus();
    fetchParentProducts();
  }, []);

  // Fetch SKUs from Firestore and include document ID
  const fetchSkus = async () => {
    setIsLoading(true);
    try {
      const skuSnapshot = await getDocs(collection(db, "skus"));
      const skuList = skuSnapshot.docs.map((doc) => ({
        docId: doc.id, // Firestore document ID
        ...doc.data(),
      }));
      setSkus(skuList);
    } catch (error) {
      console.error("Error fetching SKUs:", error);
    }
    setIsLoading(false);
  };

  // Fetch parent products from Firestore
  const fetchParentProducts = async () => {
    try {
      const parentProductsSnapshot = await getDocs(collection(db, "parent_products"));
      const parentProductList = parentProductsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setParentProducts(parentProductList);
    } catch (error) {
      console.error("Error fetching parent products:", error);
    }
  };

  // Handle input change for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  // Handle pricing change in form inputs
  const handlePricingChange = (index, field, value) => {
    const updatedPricing = [...form.pricing];
    updatedPricing[index][field] = value;
    setForm((prev) => ({ ...prev, pricing: updatedPricing }));
  };

  // Handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadImage(file);
    }
  };

  // Upload image to Firebase Storage
  const uploadImage = async (file) => {
    setIsUploading(true);
    const timestamp = Date.now(); 
    const storageRef = ref(storage, `sku_images/${timestamp}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        console.error("Error uploading image:", error);
        setIsUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setForm((prev) => ({ ...prev, imageUrl: downloadURL }));
        setIsUploading(false);
      }
    );
  };

  // Open modal for adding/editing SKU
  const openModal = (sku = initialFormState()) => {
    document.body.style.overflow = 'hidden';

    const pricing = sku.pricing && sku.pricing.length > 0 ? sku.pricing : [
      { minQuantity: 1, maxQuantity: 15, price: "" },
      { minQuantity: 16, maxQuantity: 50, price: "" },
    ];

    setForm({ ...sku, pricing });
    setEditingDocId(sku.docId || null);  // Track Firestore document ID for editing
    setIsModalOpen(true);
  };

  // Close modal and reset form state
  const closeModal = () => {
    document.body.style.overflow = 'visible';
    setIsModalOpen(false);
    setForm(initialFormState());
    setEditingDocId(null);  // Reset Firestore document ID for editing
  };

  // Check if the SKU already exists before creation
  const isDuplicateSKU = (sku) => {
    return skus.some(existingSku => existingSku.sku.toLowerCase() === sku.toLowerCase());
  };

  // Save or update SKU in Firestore
  const saveSku = async () => {
    if (!form.sku || !form.size || !form.color || !form.stock || !form.parentId) {
      toast.error("Please fill all required fields."); // Replace alert with toast
      return;
    }

    if (!form.pricing || form.pricing.length === 0) {
      toast.error("Please add pricing details."); // Replace alert with toast
      return;
    }

    // Prevent duplicate SKU creation
    if (!editingDocId && isDuplicateSKU(form.sku)) {
      toast.error('SKU already exists. Please choose a different SKU.'); // Replace alert with toast
      return;
    }

    setIsLoading(true);
    try {
      if (editingDocId) {
        // Update existing SKU using Firestore document ID
        const skuDocRef = doc(db, "skus", editingDocId);
        await updateDoc(skuDocRef, form);
        toast.success("SKU updated successfully."); // Replace alert with toast
      } else {
        // Create a new SKU in Firestore
        await addDoc(collection(db, "skus"), form);
        toast.success("SKU added successfully."); // Replace alert with toast
      }
      fetchSkus();  // Refresh the SKU list after creation or update
      closeModal(); // Close the modal after saving
    } catch (error) {
      console.error("Failed to save SKU:", error);
      toast.error("Failed to save SKU."); // Replace alert with toast
    }
    setIsLoading(false);
  };

  // Delete SKU from Firestore
  const deleteSku = async (docId) => {
    if (!docId) {
      console.error("Missing Firestore document ID for deletion");
      return;
    }

    if (window.confirm("Are you sure you want to delete this SKU?")) {
      try {
        await deleteDoc(doc(db, "skus", docId));
        toast.success("SKU deleted successfully."); // Replace alert with toast
        fetchSkus();
      } catch (error) {
        console.error("Error deleting SKU:", error);
        toast.error("Failed to delete SKU."); // Replace alert with toast
      }
    }
  };

  return (
    <div className="manage-inventory">
      <h2>Manage Inventory</h2>
      <button onClick={() => openModal()}>Add New SKU</button>

      {isLoading ? (
        <div className="loader">Loading...</div>
      ) : (
        <table className="inventory-table">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Color</th>
              <th>Size</th>
              <th>Stock</th>
              <th>Parent Product</th>
              <th>Image</th>
              <th>Pricing</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {skus.map((sku) => (
              <tr key={sku.docId}>
                <td data-label="SKU">{sku.sku}</td>
                <td data-label="Color">{sku.color}</td>
                <td data-label="Size">{sku.size}</td>
                <td data-label="Stock">{sku.stock}</td>
                <td data-label="Parent Product">
                  {parentProducts.find((p) => p.id === sku.parentId)?.name || "N/A"}
                </td>
                <td data-label="Image">
                  <img src={sku.imageUrl} alt={sku.sku} className="sku-thumbnail" />
                </td>
                <td data-label="Pricing">
                  <ul className="pricing-list">
                    {sku.pricing.map((pricing, index) => (
                      <li key={index}>
                        {pricing.minQuantity}-{pricing.maxQuantity} pcs: {pricing.price}₹
                      </li>
                    ))}
                  </ul>
                </td>
                <td data-label="Actions">
                  <button onClick={() => openModal(sku)}>Edit</button>
                  {role === "admin" && (
                    <button onClick={() => deleteSku(sku.docId)}>Delete</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h3>{editingDocId ? "Edit SKU" : "Add New SKU"}</h3>
            </div>
            <div className="modal-content">
              {/* Parent Product Dropdown */}
              <div className="form-section">
                <h6>Select Parent Product</h6>
                <select
                  name="parentId"
                  value={form.parentId}
                  onChange={handleInputChange}
                >
                  <option value="">Select Parent Product</option>
                  {parentProducts.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* SKU Details */}
              <div className="form-section">
                <h4>SKU Details</h4>
                <h6>Enter SKU</h6>
                <input
                  type="text"
                  name="sku"
                  value={form.sku}
                  onChange={handleInputChange}
                  placeholder="Enter SKU"
                  disabled={!!editingDocId} // Disable SKU input when editing
                />
                <h6>Enter Color</h6>
                <input
                  type="text"
                  name="color"
                  value={form.color}
                  onChange={handleInputChange}
                  placeholder="Enter Color"
                />
                <h6>Enter Size</h6>
                <input
                  type="text"
                  name="size"
                  value={form.size}
                  onChange={handleInputChange}
                  placeholder="Enter Size (e.g., 38, 40)"
                />
                <h6>Enter Stock</h6>
                <input
                  type="number"
                  name="stock"
                  value={form.stock}
                  onChange={handleInputChange}
                  placeholder="Enter Stock Quantity"
                />
              </div>

              {/* Pricing Section */}
              <div className="form-section">
                <h4>Pricing Details</h4>
                {form.pricing.map((pricing, index) => (
                  <div key={index} className="pricing-entry">
                    <h6>Price Range {index + 1}</h6>
                    <input
                      type="number"
                      value={pricing.minQuantity}
                      onChange={(e) => handlePricingChange(index, "minQuantity", e.target.value)}
                      placeholder="Min Quantity"
                    />
                    <input
                      type="number"
                      value={pricing.maxQuantity}
                      onChange={(e) => handlePricingChange(index, "maxQuantity", e.target.value)}
                      placeholder="Max Quantity"
                    />
                    <input
                      type="number"
                      value={pricing.price}
                      onChange={(e) => handlePricingChange(index, "price", e.target.value)}
                      placeholder="Price"
                    />
                  </div>
                ))}
              </div>

              <div className="form-section">
                <h4>Upload Image</h4>
                <input type="file" onChange={handleImageChange} />
                {form.imageUrl && (
                  <img src={form.imageUrl} alt="SKU" className="image-preview" />
                )}
              </div>
            </div>

            <div className="modal-actions">
              <button onClick={saveSku} disabled={isUploading || isLoading}>
                {isUploading ? "Uploading..." : "Save"}
              </button>
              <button onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageInventory;
