import React, { useEffect, useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase-config';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const [pendingOrders, setPendingOrders] = useState(0);
  const [skus, setSkus] = useState([]);  // State to store SKU data

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      // Fetch pending orders
      const ordersSnapshot = await getDocs(collection(db, 'orders'));
      const pending = ordersSnapshot.docs.filter(doc => doc.data().status === 'Pending');
      setPendingOrders(pending.length);

      // Fetch SKUs and their quantities
      const skusSnapshot = await getDocs(collection(db, 'skus'));
      const skusData = skusSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSkus(skusData);  // Set SKU data in state

    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  return (
    <div className="admin-dashboard">
      <main className="dashboard-content">
        <div className="card-admin">
          <h2>Pending Orders</h2>
          <p>{pendingOrders}</p>
        </div>

        <div className="card-admin">
          <h2>SKU Inventory</h2>
          <table className="sku-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>SKU Name</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {skus.map((sku) => (
                <tr key={sku.id} className={sku.stock < 15 ? 'low-stock' : ''}>
                  <td><img src={sku.imageUrl} alt={sku.sku} className="sku-image" /></td>
                  <td>{sku.sku}</td>
                  <td>{sku.stock}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
};

export default AdminDashboard;
