export const PROTECTED_PERMISSIONS = ['manage_users'];

export const ROLES = {
  ADMIN: 'admin',
  STAFF: 'staff',
  VIEWER: 'viewer'
};

export const PERMISSIONS = {
  MANAGE_ORDERS: 'manage_orders',
  MANAGE_USERS: 'manage_users',
  MANAGE_PRODUCTS: 'manage_products',
  MANAGE_INVENTORY: 'manage_inventory'
};

export const PERMISSIONS_LIST = Object.values(PERMISSIONS).filter(
  permission => !PROTECTED_PERMISSIONS.includes(permission)
); 