import React from 'react';

const CustomerInformation = ({ userDetails, order }) => {
  return (
    <div className="customer-details card">
      <h3>Customer Information</h3>
      <p><strong>Name:</strong> {userDetails?.billingName || 'NA'}</p>
      <p><strong>Email:</strong> {userDetails?.email}</p>
      <p><strong>Mobile:</strong> {userDetails?.mobileNumber || 'NA'}</p>
      <p><strong>Shipping Address:</strong></p>
      <address>{order.shippingAddress || 'NA'}</address>
    </div>
  );
};

export default CustomerInformation; 