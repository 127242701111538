import React from 'react';

const OrderInformation = ({ order, orderId, userDetails, handleViewInvoice, printOrderDetails, printManifest }) => {
  return (
    <div className="order-summary card">
      <h3>Order Information</h3>
      <p><strong>Order ID:</strong> {orderId}</p>
      <p><strong>Order Date:</strong> {order.orderDate.toDate().toLocaleString()}</p>
      <p><strong>Total Amount:</strong> ₹{order.totalAmount}</p>
      <p><strong>Payment ID:</strong> {order.razorpayOrderId || 'Pending'}</p>
      <p><strong>Status:</strong> {order.status}</p>
      <p><strong>Assigned To:</strong> {order.assignedTo || 'Not assigned'}</p>
      <button onClick={handleViewInvoice} className="btn-view-invoice">
        View Invoice
      </button>
      <div className="download-buttons">
        <button onClick={printOrderDetails} className="btn-download">
          Print Order Details
        </button>
        <button onClick={printManifest} className="btn-download">
          Print Manifest
        </button>
      </div>
    </div>
  );
};

export default OrderInformation; 