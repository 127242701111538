import React from 'react';
import { useCart } from '../../context/CartContext';
import { useNavigate } from 'react-router-dom';
import './Cart.css';

const Cart = () => {
  const { state, dispatch } = useCart();
  const navigate = useNavigate();

  const removeFromCart = (id) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id } });
  };

  const handleEdit = (item) => {
    dispatch({ type: 'SET_CURRENT_EDIT', payload: item });
    navigate(`/product/${item.id}`);
  };

  return (
    <div className="cart-container" style={{ marginTop: '10px'}}>
      <h1 className="cart-title">Your Shopping Cart</h1>
      {state.cartItems.length === 0 ? (
        <div className="empty-cart">
          <p>Your cart is empty.</p>
          <button className="browse-products-button" onClick={() => navigate('/')}>
            Browse Products
          </button>
        </div>
      ) : (
        <div>
               {state.cartItems.map((item, index) => (
            <div key={index} className="cart-item-card">
              <h4 className="product-name">{item.name}</h4>

              <table className="selection-table">
                <thead>
                  <tr>
                    <th>Color</th>
                    <th>Size</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(item.selections) && item.selections.length > 0 ? (
                    item.selections.map((selection, idx) => (
                      <tr key={idx}>
                        <td>{selection.color}</td>
                        <td>{selection.size}</td>
                        <td>{selection.quantity}</td>
                        <td>
                          {selection.lowerPrice && state.totalQuantity > 15
                            ? `${selection.lowerPrice}₹`
                            : `${selection.basePrice}₹`}
                        </td>
                        <td>
                          {(selection.lowerPrice && state.totalQuantity > 15
                            ? selection.lowerPrice
                            : selection.basePrice) * selection.quantity}₹
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No selections found.</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <p className="item-total">
                <strong>Total for {item.name} with GST: </strong>
                {item.selections.reduce(
  (total, sel) => {
    const basePrice = parseInt(sel.lowerPrice && state.totalQuantity > 15 ? sel.lowerPrice : sel.basePrice);
    const gstAmount = (sel.gst / 100) * basePrice; // Calculate GST for each item
    const priceWithGst = basePrice + gstAmount; // Total price including GST
    return total + priceWithGst * sel.quantity; // Add to total
  },
  0
)}
                ₹
              </p>

              {/* Edit and Remove Buttons */}
              <div className="button-row">
                <button className="edit-button" onClick={() => handleEdit(item)}>
                  Edit
                </button>
                <button className="remove-button" onClick={() => removeFromCart(item.id)}>
                  Remove
                </button>
              </div>
            </div>
          ))}

          <h2 className="cart-total">Total Amount: {state.totalPrice}₹</h2>
          <button className="checkout-button" onClick={() => navigate('/checkout')}>
            Proceed to Checkout
          </button>
        </div>
      )}
    </div>
  );
};

export default Cart;
