import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CartProvider } from './context/CartContext';
import { ToastProvider } from './context/ToastContext';
import AdminRoutes from './routes/AdminRoutes';
import UserRoutes from './routes/UserRoutes';
import Navbar from './components/Navbar/Navbar';
import AdminNavbar from './admin/Adminnavbar';
import useAdminRole from './hooks/useAdminRole';
import Login from './pages/Login/Login';
import Invoice from './admin/Invoice';

function App() {
  const { role } = useAdminRole();

  return (
    <ToastProvider>
      <CartProvider>
        <Router>
          <div>
            {/* Conditionally render the navigation bar */}
            {role === 'admin' || role === 'staff' ? <AdminNavbar /> : <Navbar />}
            {/* Routing for the app */}
            <Routes>
              {role === 'admin' || role === 'staff' ? <Route path="/admin/*" element={<AdminRoutes />} /> : <Route path="/*" element={<UserRoutes />}/>}
              <Route path="/login" element={<Login />} key="login" />,
              <Route path="/invoice/:invoiceId" element={<Invoice />} />
            </Routes>
          </div>
        </Router>
      </CartProvider>
    </ToastProvider>
  );
}

export default App;
