import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { app } from '../firebase-config';
import { doc, getDoc, updateDoc, collection, getDocs, setDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import './OrderDetails.css';

// Import components
import OrderInformation from './components/OrderDetails/OrderInformation';
import CustomerInformation from './components/OrderDetails/CustomerInformation';
import OrderedItems from './components/OrderDetails/OrderedItems';
import OrderActions from './components/OrderDetails/OrderActions';
import { printOrderDetails, printManifest } from './utils/printUtils';

const OrderDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newStatus, setNewStatus] = useState('');
  const [showShippingInput, setShowShippingInput] = useState(false);
  const [shippingLink, setShippingLink] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [assignedStaff, setAssignedStaff] = useState('');
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const orderRef = doc(db, 'orders', orderId);
        const orderDoc = await getDoc(orderRef);

        if (orderDoc.exists()) {
          const orderData = orderDoc.data();
          setOrder(orderData);
          setNewStatus(orderData.status);
          setAssignedStaff(orderData.assignedTo || '');

          if (orderData.userId) {
            const userRef = doc(db, 'users', orderData.userId);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
              setUserDetails(userDoc.data());
            }
          }
        } else {
          console.error('Order not found');
          navigate('/admin/manage-orders');
        }
      } catch (error) {
        console.error('Error fetching order:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchStaff = async () => {
      try {
        const usersCollection = collection(db, 'admin_users');
        const userSnapshot = await getDocs(usersCollection);
        const staffData = userSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => user.role === 'staff');
        setStaffList(staffData);
      } catch (error) {
        console.error('Error fetching staff:', error);
      }
    };

    fetchOrder();
    fetchStaff();
  }, [orderId, navigate]);

  const handleStatusChange = async () => {
    try {
      if (newStatus !== order.status) {
        const orderRef = doc(db, 'orders', orderId);
        await updateDoc(orderRef, { status: newStatus });

        if (newStatus === 'Shipped') {
          setShowShippingInput(true);
        } else {
          setShowShippingInput(false);
        }

        setOrder({ ...order, status: newStatus });
        alert('Order status updated successfully!');
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      alert('Failed to update order status.');
    }
  };

  const handleShippingSubmit = async () => {
    if (!shippingLink) {
      alert('Please enter a shipping ID or link.');
      return;
    }

    try {
    
      if (!order.mobileNumber || !order.userEmail || !orderId) {
        console.log(order)
       return;
      }
      const functions = getFunctions(app, "asia-south1");
      const notifyOrderShipped = httpsCallable(functions, "notifyOrderShipped");
      await notifyOrderShipped({
        mobileNumber: order.mobileNumber,
        billingName: order.userEmail,
        orderId: orderId,
        trackingLink: shippingLink,
      });
      alert('Shipping notification sent successfully!');
    } catch (error) {
      console.error('Error sending shipping notification:', error);
      alert('Failed to send shipping notification.');
    }
  };

  const handleAssignStaff = async () => {
    try {
      const orderRef = doc(db, 'orders', orderId);
      await updateDoc(orderRef, { assignedTo: assignedStaff });
      setOrder({ ...order, assignedTo: assignedStaff });
      alert('Staff member assigned successfully!');
    } catch (error) {
      console.error('Error assigning staff:', error);
      alert('Failed to assign staff.');
    }
  };

  const handleViewInvoice = async () => {
    try {
      const invoiceId = `INV-${Date.now()}-${orderId}`;
      if (!order.invoiceId) {
        const invoiceRef = doc(db, 'invoices', invoiceId);
        await setDoc(invoiceRef, {
          userId: order.userId || 'NA',
          orderId: orderId,
          invoiceNo: invoiceId,
          products: order.items,
          paymentStatus: order.paymentStatus || 'NA',
          stateCode: 'NA',
          hsnCode: 'NA',
          rate: order.totalAmount,
          gst: 'NA',
          total: order.totalAmount,
          address: order.shippingAddress || 'NA',
          mobileNumber: userDetails?.mobileNumber || 'NA',
          gstNo: 'NA',
          date: order.orderDate,
          createdAt: new Date()
        });

        const orderRef = doc(db, 'orders', orderId);
        await updateDoc(orderRef, { invoiceId });
        
        setOrder({ ...order, invoiceId });
      }
      
      navigate(`/invoice/${order.invoiceId || invoiceId}`);
    } catch (error) {
      console.error('Error handling invoice:', error);
      alert('Failed to generate invoice.');
    }
  };

  if (loading) return <div>Loading order details...</div>;

  return (
    <div className="order-details-container">
      <h2 className="order-details-title">Order Details</h2>

      <div className="main-content">
        <div className="left-column">
          <OrderInformation 
            order={order}
            orderId={orderId}
            userDetails={userDetails}
            handleViewInvoice={handleViewInvoice}
            printOrderDetails={() => printOrderDetails(order, orderId, userDetails)}
            printManifest={() => printManifest(order, orderId, userDetails)}
          />
          <CustomerInformation 
            userDetails={userDetails}
            order={order}
          />
        </div>

        <div className="center-column">
          <OrderedItems items={order.items} />
        </div>

        <div className="right-column">
          <OrderActions 
            newStatus={newStatus}
            setNewStatus={setNewStatus}
            handleStatusChange={handleStatusChange}
            assignedStaff={assignedStaff}
            setAssignedStaff={setAssignedStaff}
            handleAssignStaff={handleAssignStaff}
            staffList={staffList}
            showShippingInput={showShippingInput}
            shippingLink={shippingLink}
            setShippingLink={setShippingLink}
            handleShippingSubmit={handleShippingSubmit}
          />
        </div>
      </div>

      <button onClick={() => navigate('/admin/manage-orders')} className="btn-back">
        Back to Orders
      </button>
    </div>
  );
};

export default OrderDetails;
