// src/pages/ManageUsers.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { ROLES, PERMISSIONS, PERMISSIONS_LIST } from '../constants/adminConstants';
import MultiSelectDropdown from '../components/MultiSelectDropdown/MultiSelectDropdown';
import { useToast } from '../context/ToastContext';
import './ManageUsers.css';

const ManageUsers = () => {
  const toast = useToast();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editData, setEditData] = useState({ 
    role: '', 
    permissions: []
  });

  // Add this useEffect for handling clicks outside dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdowns = document.getElementsByClassName('dropdown-content');
      Array.from(dropdowns).forEach(dropdown => {
        if (!dropdown.contains(event.target) && 
            !event.target.classList.contains('dropdown-trigger')) {
          dropdown.style.display = 'none';
        }
      });
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Fetch users from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'admin_users');
        const userSnapshot = await getDocs(usersCollection);
        const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(userList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  // Handle role and permissions change
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    if (name === 'permissions') {
      // For multi-select, e.target.value is already an array of selected values
      setEditData({ ...editData, permissions: Array.from(e.target.selectedOptions, option => option.value) });
    } else {
      setEditData({ ...editData, [name]: value });
    }
  };

  // Handle permission checkbox change
  const handlePermissionChange = (permission) => {
    setEditData(prev => {
      const newPermissions = prev.permissions.includes(permission)
        ? prev.permissions.filter(p => p !== permission)
        : [...prev.permissions, permission];
      return { ...prev, permissions: newPermissions };
    });
  };

  // Save changes to the user
  const saveChanges = async (userId) => {
    try {
      const userDoc = doc(db, 'admin_users', userId);
      await updateDoc(userDoc, { role: editData.role, permissions: editData.permissions });
      setUsers(users.map(user => user.id === userId ? { ...user, role: editData.role, permissions: editData.permissions } : user));
      setEditingUserId(null);
      toast.success('User updated successfully');
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error('Failed to update user');
    }
  };

  // Add delete user function
  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        const userDoc = doc(db, 'admin_users', userId);
        await deleteDoc(userDoc);
        setUsers(users.filter(user => user.id !== userId));
        toast.success('User deleted successfully');
      } catch (error) {
        console.error("Error deleting user:", error);
        toast.error('Failed to delete user');
      }
    }
  };

  if (loading) return <div>Loading users...</div>;

  return (
    <div className="manage-users">
      <h2>Manage Users</h2>
      <div className="table-container">
        <table className="users-table">
          <thead>
            <tr>
              <th>User ID</th>
              <th>Email</th>
              <th>Role</th>
              <th>Permissions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td data-label="User ID">{user.id}</td>
                <td data-label="Email">{user.email}</td>
                <td data-label="Role">
                  {editingUserId === user.id ? (
                    user.role === ROLES.ADMIN ? (
                      user.role // If user is admin, just show text
                    ) : (
                      <select
                        name="role"
                        value={editData.role}
                        onChange={handleEditChange}
                      >
                        <option value={ROLES.STAFF}>{ROLES.STAFF}</option>
                        <option value={ROLES.VIEWER}>{ROLES.VIEWER}</option>
                      </select>
                    )
                  ) : (
                    user.role
                  )}
                </td>
                <td data-label="Permissions">
                  {editingUserId === user.id ? (
                    <MultiSelectDropdown
                      options={PERMISSIONS_LIST.filter(permission => permission !== PERMISSIONS.MANAGE_USERS)}
                      selected={editData.permissions}
                      onChange={handlePermissionChange}
                      disabled={user.role === ROLES.ADMIN}
                    />
                  ) : (
                    user.permissions.join(', ')
                  )}
                </td>
                <td data-label="Actions">
                  {editingUserId === user.id ? (
                    <button onClick={() => saveChanges(user.id)} className="btn-save">Save</button>
                  ) : (
                    user.role === ROLES.ADMIN ? (
                      // Don't show edit button for admin users
                      <span className="admin-note">Admin user</span>
                    ) : (
                      <div className="action-buttons">
                        <button 
                          onClick={() => {
                            setEditingUserId(user.id);
                            setEditData({ role: user.role, permissions: user.permissions });
                          }} 
                          className="btn-edit"
                        >
                          Edit
                        </button>
                        <button 
                          onClick={() => handleDeleteUser(user.id)} 
                          className="btn-delete"
                        >
                          Delete
                        </button>
                      </div>
                    )
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageUsers;
